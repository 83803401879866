<template>
	<el-container style="height:100%; border: 1px solid #eee">
		<!--left start-->
		<el-aside width="200px" style="background-color: #1C1C1E;">
			<el-row class="tac">
				<el-col>
					<div style="text-align: center;padding-top: 20px;">
						<img src="../../assets/images/logo.png" style="width: 100px;">
					</div>
					<el-menu
						:router="true"
						:default-active="this.$route.path"
						class="el-menu-vertical-demo"
						background-color="#1C1C1E"
						text-color="#fff"
						active-text-color="#ffd04b">
						<el-submenu index="news">
							<template slot="title"><i class="el-icon-video-camera"></i><span>新闻资讯</span></template>
							<el-menu-item index="/admins/news/list" >新闻列表</el-menu-item>
							<el-menu-item index="/admins/news/category" >新闻分类</el-menu-item>
						</el-submenu>
						<el-submenu index="policy">
							<template slot="title"><i class="el-icon-s-flag"></i><span>政策法规</span></template>
							<el-menu-item index="/admins/pdicies/list" >政策法规列表</el-menu-item>
							<el-menu-item index="/admins/pdicies/category" >政策法规分类</el-menu-item>
						</el-submenu>
						<el-submenu index="tender">
							<template slot="title"><i class="el-icon-s-data"></i><span>招标信息</span></template>
							<el-menu-item index="/admins/tenderInfo/tenderList" >招标信息列表</el-menu-item>
						</el-submenu>
            <el-submenu index="setting">
              <template slot="title"><i class="el-icon-s-tools"></i><span>网站设置</span></template>
              <el-menu-item index="/admins/setting/carousel/list" >轮播图管理</el-menu-item>
            </el-submenu>
						<el-menu-item index="/admins/activity" ><i class="el-icon-help"></i>报名入口</el-menu-item>
					</el-menu>
				</el-col>
			</el-row>
		</el-aside>
		<!--left end-->
		<el-container>
		<!--head start-->
			<el-header style="text-align: right; font-size: 12px;padding-top:10px;">
				<i class="el-icon-message-solid" style="font-size:16px;float:left;margin-top: 13px;margin-right:5px;"></i>
				
				<span style="font-size:16px;float: left;margin-top: 10px;">欢迎来到管理后台</span>
				<i class="el-icon-user-solid" style="margin-right: 15px;font-size: 30px;"></i>
				<el-dropdown>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item ><span @click="exitLogin()">退出</span></el-dropdown-item>
					</el-dropdown-menu>
					<i class="el-icon-caret-bottom" style="margin-right: 15px"></i>
				</el-dropdown>
				<el-divider><i class="el-icon-mobile-phone"></i></el-divider>
			</el-header>
		<!--head end-->
		<el-main>
			<router-view></router-view>
		</el-main>
		</el-container>
	</el-container>
</template>
<script>
export default {
    data() {return {}},
	beforeCreate:function(){
		if(document.cookie.indexOf("touBiaoVerify")== -1){
			this.$router.push('/institute')
		}
	},
	methods:{
		exitLogin(){
			document.cookie = "touBiaoVerify=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
			this.$router.push('/institute')
		}
	}
}
</script>

<style scoped>
	.el-header {
		color: #333;
	}
	.el-menu-item:hover,.el-submenu__title:hover{
		background: #1c88cf !important;
		color: #fff !important;
	}
	.el-menu{
		border-right:none;
		background-color:#fff;
	}
	.formSearch .el-input{width:217px;}
</style>